import React from "react"
import { Link } from "gatsby"

import { Modal, Layout, SEO } from "../components/"
import { Landing, Skills, Footer } from "../sections/"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `crrumb`,
        `web`,
        `development`,
        `application`,
        `react`,
        `gatsbyjs`,
      ]}
    />
    <Landing />
    <Skills />
    <Footer />
    <Modal />
  </Layout>
)

export default IndexPage
