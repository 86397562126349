import React from "react"
import styled from "styled-components"

const Impressed = styled.div`
  padding: 250px 0;
  h3 {
    color: #2C291D;
    font-size: 3rem;
    margin: 0;
    line-height: 120%;
  }
  p {
    font-size: 1rem;
    color: #B5473C;
  }
`

const FooterStyled = styled.footer`
  p {
  font-size: 0.75rem;
  }
  a {
    color: #B5473C;
  }
`

const Footer = () => (
  <>
    <section>
      <Impressed>
        <h3>Thank You</h3>
        <p>
            hello@crrumb.com
        </p>
      </Impressed>
    </section>
    <FooterStyled>
      <p>
        Crrumb&copy;{new Date().getFullYear()}, Built with <a href="https://www.gatsbyjs.org">Gatsby</a> and set with a lovely typeface from <a href="https://www.velvetyne.fr">VTF</a> 
      </p>
    </FooterStyled>
  </>
)
export default Footer
