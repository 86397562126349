import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const SubTitle = styled.h2`
  margin: auto;
  font-size: 3rem;
`

const SkillsStyled = styled.section`
  padding-top: 200px;
  width: 100%;
`

const Star = styled.span`
  color: red;
  padding-left: 8px;
`

const List = styled.div`
  display: flex;
  div:nth-of-type(-n + 2) {
    border-right: 1px solid black;
    ${media.lessThan("medium")`
      border-right: none;
      border-bottom: 1px solid black;
    `}
  }
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`

const Set = styled.div`
  width: calc(100% / 3);
  padding: 0 12px;
  ${media.lessThan("medium")`
    width: 100%;
    padding: 12px 0;
  `}
  p {
    line-height: 150%;
  }
  a {
    color: #b5473c;
  }
`

const Skills = () => (
  <SkillsStyled>
    <SubTitle>Keep it simple.</SubTitle>
    <p>
      What we do is very simple: build websites. Here are three levels of tools
      to understand our approach.
    </p>
    <List>
      <Set>
        <h3>1. Javascript</h3>
        <p>
          Javascript is the main programming language we use for building
          digital products (even this website.)
        </p>
        <p>This the starting point of everything.</p>
      </Set>
      <Set>
        <h3>2. React</h3>
        <p>
          Not a programming language, but a popular Javascript library created
          by Facebook (
          <a href="https://reactjs.org" rel="noopener noreferrer">
            reactjs.org
          </a>
          )
        </p>
        <p>With React, it is also possible to create web applications.</p>
      </Set>
      <Set>
        <h3>3. GatsbyJS</h3>
        <p>
          A static site generator built based on React. (
          <a href="https://www.gatsbyjs.org/" rel="noopener noreferrer">
            gatsbyjs.org
          </a>
          ) We believe it is one of the best choices for the front end of
          headless CMS.
        </p>
        <p>
          Gatsby sites follow the latest web standards/technology, and are
          optimized to be highly performant.
        </p>
      </Set>
    </List>
  </SkillsStyled>
)

export default Skills
