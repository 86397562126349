import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const Title = styled.div`
  h1 {
    margin-top: 333px;
    font-size: 6rem;
    margin-bottom: 0;
    ${media.lessThan("medium")`
      margin-top: 200px;
      font-size: 3.25rem;
    `}
  }
  h3 {
    opacity: 0.8;
    margin-bottom: 8px;
  }
  p {
    font-size: 1.125rem;
  }
`

const Star = styled.span`
  color: #B5473C;
  padding-left: 8px;
  font-size: 2rem;
`

const SmallStar = styled.span`
  color: #D7B545;
`

const Partner = styled.span`
  padding: 1px 4px;
  background: #E2BC57;
`

const Landing = () => (
  <section>
    <Title>
      <h1>A web development stu-duo<SmallStar>*</SmallStar> located in Toronto.</h1>
      <h3>
        <Star>*</Star>Yeah, only two people.
      </h3>
    </Title>
  </section>
)

export default Landing
